import request from '@/routes/api/api_conextion';

export function checkCode(params) {
    return request({
        url: '/formulario/controlCodigo',
        method: 'get',
        params: params
    })
}

export function resendVerificationCode(code) {
    return request({
        url: '/formulario/enviarCodigo/' + code,
        method: 'put',
    })
}

export function getOferta(id_solicitud, params) {
    return request({
        url: `/formulario/proposal/` + id_solicitud,
        method: 'get',
        params: params
    });
}

export function addGarante(params) {
    return request({
        url: `/formulario/garante/` + params.loan,
        method: 'post',
        data: params
    })
}

export function aceptOferta(params) {
    return request({
        url: `/formulario/accept`,
        method: 'post',
        data: params
    })
}

export function segundoFormularioConsumo(params) {
    return request({
        url: `/formulario/` + params.loan,
        method: 'put',
        data: params
    })
}

export function getParametersToAccomplish() {
    return request({
        url: '/formulario/getParametersToAccomplish',
        method: 'get',

    })
}

export function simularCustom(params) {
    return request({
        url: '/formulario/simularFront',
        method: 'post',
        data: params
    })
}

export async function getProduct(product_id, number_step = 1, garante = false) {

    return request({
        url: `/loancustom/` + product_id,
        method: 'get',
        params: {step: number_step,garante: garante},
    })
}

export function getAllProfiles(params) {
    return request({
        url: `/loancustom/all/` + params.type,
        method: 'get',
    })
}

export function getCotizacionDia(params) {
    return request({
        url: `/cotizacion/cambioventa/` + params.id,
        method: 'get',
    })
}

export function addLoanCustom(params) {
    return request({
        url: `/formulario`,
        method: 'post',
        data: params
    })
}

export function addPymeComponent(params) {
    return request({
        url: `/formulario/addPymeComponent/` + params.loan,
        method: 'post',
        data: params
    })
}

export function getDocumentacion(params) {
    return request({
        url: `/getdocumentacion/` + params.id + '/' + params.type,
        method: 'get',
    })
}

export function setApplicant(params) {
    return request({
        url: '/formulario/applicant',
        method: 'post',
        data: params
    })
}

export function getProductStep(params) {
    return request({
        url: 'product/step',
        method: 'post',
        data: params
    })
}

export function checkApplicant(params) {
    return request({
        url: '/checkApplicantActive',
        method: 'post',
        data: params
    })
}

export function getCalcularLimite(params) {
    return request({
        url: '/getCalcularLimite',
        method: 'post',
        data: params
    })
}

export function checkEquifax(params) {
    return request({
        url: '/checkApplicantFinal',
        method: 'post',
        data: params
    })
}