import {isEmpty} from "@/commons/utils/functions";
import Vue from 'vue'
import Router from "vue-router";
import i18n from "@/lang/lang_config";
import store from "@/store";
import Index from "@/views/Index";

import {getAlias, getToken,getLang} from "@/commons/utils/auth";

export function lazyLoad(view) {
    return () => import(`@/views/${view}.vue`)
}

///routes
import productos from "@/routes/app/productos";
import documentacion from "@/routes/app/documentacion";
import docSign from "@/commons/forms/DigitalSings/Docusign/PzDocusignEnd"

Vue.use(Router);
let routes = [
    {
        path: "/",
        name: "App",
        component: Index,
        meta: {title: "Home", show: false},
        redirect: {name: '404'},
        children: [
            {
                path: "/404",
                component: lazyLoad('page_errors/PzNotFound'),
                name: "404",
                meta: {
                    hidden: true,
                    title: 'errors._404.header'
                }
            },
            {
                path: "/401",
                component: lazyLoad('page_errors/PzNotAuthorized'),
                name: "401",
                meta: {
                    hidden: true,
                    title: 'errors._401.header'
                }
            },

        ]
    },
    {
        path: "/firmas/:envelopId/:alias",
        name: "DocusingEnd",
        component: docSign,
        props: true,
    },
    {
        path: "/:alias",
        name: "Products",
        component: Index,
        meta: {title: "Home", show: false},
        redirect: {path: '/:alias/producto/all/person'},
    },
    {
        path: "/:alias/producto",
        name: "Prestamos",
        component: Index,
        meta: {title: "Home", show: false},
        redirect: {path: '/:alias/producto/all/person'},
        children: productos
    },
    {
        path: "/:alias/documentacion",
        name: "DocumentationExtra",
        component: Index,
        meta: {show: false},
        redirect: {path: '/:alias/documentacion/code'},
        children: documentacion
    },


    /**
     * Errors**/

    {
        path: "*",
        hidden: true,
        redirect: {
            path: "/404"
        },
        meta: {
            hidden: true
        }
    },
// Documentacion,

];
const router = new Router({
    routes: routes,
    //mode: 'history'
});
const whiteList = ['App', '404', '401', 'DocusingEnd']
//const blackList = ['Producto']

router.beforeEach((to, from, next) => {
    store.dispatch('loader/up', {trigger: 'route enter'});
    const hasToken = getToken();
    const hasAlias = getAlias();
    const Lang = getLang();
    store.dispatch('app/setLocale', Lang );
    if (whiteList.indexOf(to.name) !== -1) {
        store.dispatch('app/setHeader', {title: i18n.tc(to.meta.title), sub_title: i18n.tc(to.meta.sub_title)});
        next()
    } else if (isEmpty(hasToken) || hasAlias !== to.params.alias) {
        store.dispatch('auth/login', to.params.alias).then(() => {
            if (to.meta.title || to.meta.sub_title) {
                store.dispatch('app/setHeader', {title: i18n.tc(to.meta.title), sub_title: i18n.tc(to.meta.sub_title)});
                store.dispatch('app/googleTagManger', i18n.tc(to.meta.title));
            }
            next();
        })
    } else {
        store.dispatch('app/setHeader', {title: i18n.tc(to.meta.title), sub_title: i18n.tc(to.meta.sub_title)});
        next();
    }

});

export default router;

