import {
    getAlias,
    getNameCompany,
    getToken,
    removeAlias,
    removeNameCompany,
    removeToken,
    setAlias, setNameCompany,
    setToken
} from "@/commons/utils/auth";
import {login} from "@/routes/api/auth";
import router from "@/routes/app/routes";

const state = {
    token: getToken(),
    alias: getAlias(),
    company: getNameCompany(),
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;

    },
    SET_ALIAS: (state, alias) => {
        state.alias = alias;
    },
    SET_COMPANY: (state, company) => {
        state.company = company;
    },
};
const actions = {
    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken();
            commit('SET_ALIAS', '')
            removeAlias();
            commit('SET_COMPANY', '')
            removeNameCompany();
            resolve()
        })
    },
    notAuthorized({dispatch}) {
        return new Promise(resolve => {
            dispatch('auth/resetToken', null, {root: true});
            router.push({name: '401'});
            this.$store.dispatch('loader/down',{trigger:'route_enter.no_auth'});
            resolve()
        })
    },
    login({commit, dispatch}, alias) {
        return new Promise((resolve) => {
            login(alias).then(response => {
                const {token, lang,name_company} = response.data;
                commit('SET_TOKEN', token);
                setToken(token);
                setAlias(alias);
                setNameCompany(name_company);
                this._vm.$session.set('username', alias);
                dispatch('app/setLocale', lang, {root: true});
                dispatch('app/getDirection', null, {root: true});
                resolve();
            }).catch(error => {
                console.log(error);
            })
        })
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
