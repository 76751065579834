import {getDirectionConfig, getIp} from "@/routes/api/resources";
import getPageTitle, {arrayFind} from "@/commons/utils/functions";
import {isEmpty} from '@/commons/utils/functions';
import mime from "mime-types";
import i18n from '@/lang/lang_config'
import {getDirection, setDirecction,getLang,setLang} from "@/commons/utils/auth";
//import settings from '@/settings/settings'
import router from "@/routes/app/routes";


const state = {
    location: {'country_code': ''},
    address: getDirection(),
    lang:  getLang(),
    header: {
        title: '',
        sub_title: '',
    }
}


const mutations = {
    SET_LOCATION: (state, location) => {
        state.location = location;
    },
    SET_HEADER: (state, head) => {
        state.header = head;
    },
    SET_DIRECTION: (state, direction) => {
        state.address = direction;
    },
    SET_LANG: (state, lang) => {
        state.lag = lang;
    },
};

const actions = {
    setHeader({commit}, head) {
        commit('SET_HEADER', head);
        document.title = getPageTitle(i18n.tc(head.title))
    },
    async getLocation({commit}) {
        getIp().then(response => {
            commit('SET_LOCATION', response.data);
        }).catch(() => {
            console.info('not loacation avaible');
        });
    },
    async getDirection({commit}) {
        getDirectionConfig().then(response => {
            commit('SET_DIRECTION', response.data);
            setDirecction(response.data)
        }).catch(() => {
            console.info('not direcction avaible');
        });
    },
    // eslint-disable-next-line no-empty-pattern
    getCountryFrom({}, countries) {
        return arrayFind(countries, (elem) => {
            return (elem.iso).toString().toLowerCase() === state.location.country_code.toString().toLowerCase();
        });
    },
    // eslint-disable-next-line no-unused-vars
    setMessage({dispatch}, message) {
        const h = this._vm.$createElement;
        message = message ?? i18n.tc('connexion_error');
        return this._vm.$message({
            showClose: true,
            message: h('p', {style: 'margin-right:20px'}, [
                h('div', {},
                    message),
            ]),
            duration: 40000
        });
    },
    setLocale({commit}, command) {
        i18n.locale = command;
        commit('SET_LANG', command);
        setLang(command)
        let locale = i18n.locale === 'uy' ? 'es' : i18n.locale;
        if(command == 'bnf' )
            locale = 'bnf';
        this._vm.$moment.locale(locale);
    },
    catchErrors({dispatch}, {_this, error}) {
        if (!isEmpty(error.response) && error.response.data.errors !== undefined) {
            if ((!error.response.data.errors.general) === true) {
                _this.errors.record(error.response.data.errors);
                if (!(!error.response.data.errors.captcha) === true) {
                    dispatch('setMessage', error.response.data.errors.captcha
                        , {root: false});
                }
            } else {
                dispatch('setMessage',
                    error.response.data.errors.general
                    , {root: false});
            }
        } else {
            dispatch('setMessage', _this.$t('connexion_error')
                , {root: false});
        }
        if (process.env !== 'production') {
            console.warn('[PZ Warn][Caught Error]: ' + error);
        }
        console.log('error catc')
    },
    // eslint-disable-next-line no-unused-vars
    googleTagManger({commit}, name) {
        if (isEmpty(name)) {
            name = name ? router.currentRoute.meta.title : router.currentRoute.fullPath;
        }

        this._vm.$gtm.push({event: name})
    },
    // eslint-disable-next-line no-unused-vars
    getFileTypeExtencions({commit}, fileType) {
        return mime.extension(fileType)
    },

}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
